import {DownOutlined} from '@ant-design/icons'
import Footer from '../../../../components/common/Footer'
import {SearchInput} from '../../../../components/common/Layout/Header/components/SearchInput'
import {useState} from 'react'
import {useAtom} from 'jotai'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {
    dark, vscDarkPlus, darcula, gruvboxDark,
    a11yDark, atomDark, base16AteliersulphurpoolLight, cb, coldarkCold, coldarkDark, coy, duotoneDark, duotoneForest,
    duotoneLight,duotoneSea,duotoneSpace,funky,ghcolors,gruvboxLight,hopscotch,materialDark,materialLight,materialOceanic,
    nord,okaidia, oneDark,oneLight,pojoaque,prism,shadesOfPurple,solarizedlight, synthwave84,tomorrow,twilight,vs,xonokai
} from 'react-syntax-highlighter/dist/esm/styles/prism';// 代码高亮主题风格
import remarkStringify from 'remark-stringify'
import {HttpApiCatalog} from '../../../../store/search'
import {useRequest} from 'ahooks'
import {unified} from 'unified'
import remarkHeadings, {Heading} from '@vcarl/remark-headings'
import remarkParse from 'remark-parse'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import './style.less'
import {Anchor, Spin} from 'antd'
// import apiPath from './apiPath'

import scApiPath, { SC_TYPE_KEY } from '../SellerCenterMainPage/apiPath'
import cdpApiPath, { CDP_TYPE_KEY } from '../SE_CDP_MainPage/apiPath'
import maApiPath, { MA_TYPE_KEY } from '../SE_MA_MainPage/apiPath'
import integrationApiPath, { Integration_TYPE_KEY } from '../SE_Integration_MainPage/apiPath'
import ceApiPath, { CE_TYPE_KEY } from '../SE_CE_MainPage/apiPath'
import seApiPath, { SE_TYPE_KEY } from '../SE_Setting_MainPage/apiPath'
import apiApiPath, { API_TYPE_KEY } from '../PlatformMainPage/apiPath'
import shopApiPath, { Shop_TYPE_KEY } from '../SE_Shop_MainPage/apiPath'
import faasFulfillmentApiPath, { FAAS_Fulfillment_TYPE_KEY } from '../SE_FAAS_Fulfillment_MainPage/apiPath'
import faasLoyaltyApiPath, { FAAS_Loyalty_TYPE_KEY } from '../SE_FAAS_Loyalty_MainPage/apiPath'
import faasContentApiPath, { FAAS_Content_TYPE_KEY } from '../SE_FAAS_Content_MainPage/apiPath'
import faasCdpApiPath, { FAAS_CDP_TYPE_KEY } from '../SE_FAAS_CDP_MainPage/apiPath'
import faasMaApiPath, { FAAS_MA_TYPE_KEY } from '../SE_FAAS_MA_MainPage/apiPath'

import {AnchorContainer} from 'antd/lib/anchor/Anchor'
import {PlatformMainPage} from '../PlatformMainPage'
import useScrollToTop from '../../../../hooks/useScrollToTop'
import {SellerCenterMainPage} from '../SellerCenterMainPage'

import {SE_CDP_MainPage} from '../SE_CDP_MainPage'
import {SE_MA_MainPage} from '../SE_MA_MainPage'
import {SE_Integration_MainPage} from '../SE_Integration_MainPage'

import {SE_CE_MainPage} from '../SE_CE_MainPage'
import {SE_Setting_MainPage} from '../SE_Setting_MainPage'
import {SE_Shop_MainPage} from '../SE_Shop_MainPage'
import {FAAS_Fulfillment_MainPage} from '../SE_FAAS_Fulfillment_MainPage'
import {FAAS_Loyalty_MainPage} from '../SE_FAAS_Loyalty_MainPage'
import {FAAS_Content_MainPage} from '../SE_FAAS_Content_MainPage'
import {FAAS_CDP_MainPage} from '../SE_FAAS_CDP_MainPage'
import {FAAS_MA_MainPage} from '../SE_FAAS_MA_MainPage'

import {useParams} from 'react-router-dom'
import { TApiVersion } from "../../index"

const {Link} = Anchor
export type HttpApiCatalogType = SC_TYPE_KEY | CDP_TYPE_KEY | MA_TYPE_KEY | Integration_TYPE_KEY | CE_TYPE_KEY | SE_TYPE_KEY | API_TYPE_KEY | Shop_TYPE_KEY | FAAS_Fulfillment_TYPE_KEY | FAAS_Loyalty_TYPE_KEY | FAAS_Content_TYPE_KEY | FAAS_CDP_TYPE_KEY | FAAS_MA_TYPE_KEY

//  transform normal text to anchor id
const toLowerCaseSpiltByline = (text?: string) => text && text.toLowerCase().split(' ').join('-')
// transform flat header list to tree header list
type Levels = Partial<Heading> & { children?: Levels[] }
const transHeaderListToAnchorData = (list: Heading[]) => {
    const levels: Levels[] = [{}]
    list.forEach((item) => {
        levels.length = item.depth
        levels[item.depth - 1].children = levels[item.depth - 1].children || []
        levels[item.depth - 1].children!.push(item)
        levels[item.depth] = item
    })
    return levels[0].children
}




// vscDarkPlus vscode 暗色主题
// darcula  webstorm 暗色主题
// coyWithoutShadows 上面展示的效果
const them = {
    dark: dark,
    vscDarkPlus: vscDarkPlus,
    gruvboxDark: gruvboxDark,
    darcula: darcula,
    a11yDark: a11yDark,
    atomDark: atomDark,
    base16AteliersulphurpoolLight: base16AteliersulphurpoolLight,
    cb: cb,
    coldarkCold: coldarkCold,
    coldarkDark: coldarkDark,
    coy: coy,
    duotoneDark: duotoneDark,
    duotoneForest: duotoneForest,
    duotoneLight: duotoneLight,
    duotoneSea: duotoneSea,
    duotoneSpace: duotoneSpace,
    funky: funky,
    ghcolors: ghcolors,
    gruvboxLight: gruvboxLight,
    hopscotch: hopscotch,
    materialDark: materialDark,
    materialLight: materialLight,
    materialOceanic: materialOceanic,
    nord: nord,
    okaidia: okaidia,
    oneDark: oneDark,
    oneLight: oneLight,
    pojoaque: pojoaque,
    prism: prism,
    shadesOfPurple: shadesOfPurple,
    solarizedlight: solarizedlight,
    synthwave84: synthwave84,
    tomorrow: tomorrow,
    twilight: twilight,
    vs: vs,
    xonokai: xonokai,
};

const reactMarkdownComponents = {
    h1: ({node, ...props}: any) => <h1 {...props} id={toLowerCaseSpiltByline(props.children?.[0])}/>,
    h2: ({node, ...props}: any) => <h2 {...props} id={toLowerCaseSpiltByline(props.children?.[0])}/>,
    h3: ({node, ...props}: any) => {
        const _text = (props.children?.[0] ?? "").replace(/{\#([\s\S]*)}/g, () => "");
        const _id = ((props.children?.[0] ?? "").match(/{\#([\s\S]*)}/) || ['', toLowerCaseSpiltByline(props.children?.[0])])[1];
        return <h3 {...props} id={_id}>{_text}</h3>
    },
    code: ({node, inline, className, children, ...props}: any) => {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
            <SyntaxHighlighter
                showLineNumbers={false}
                style={them.a11yDark}
                language={match[1]}
                PreTag='div'
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        ) : (
            <code className={className} {...props}>
                {children}
            </code>
        );
    }
}
const toLowerCaseSpiltBylineForAnchor = (item?: string) => toLowerCaseSpiltByline(`#${item}`)

export const mainPage = {
    PlatformAPIs: {
        home: <PlatformMainPage/>,
        apiPath: apiApiPath,
    },
    SellerCenter: {
        home: <SellerCenterMainPage/>,
        apiPath: scApiPath,
    },
    doc_CDP: {
        home: <SE_CDP_MainPage/>,
        apiPath: cdpApiPath,
    },
    doc_MA: {
        home: <SE_MA_MainPage/>,
        apiPath: maApiPath
    },
    doc_Integration: {
        home: <SE_Integration_MainPage/>,
        apiPath: integrationApiPath
    },
    doc_CE: {
        home: <SE_CE_MainPage/>,
        apiPath: ceApiPath,
    },
    doc_Setting: {
        home: <SE_Setting_MainPage/>,
        apiPath: seApiPath
    },
    doc_Shop: {
        home: <SE_Shop_MainPage/>,
        apiPath: shopApiPath
    },
    doc_Fulfillment: {
        home: <FAAS_Fulfillment_MainPage/>,
        apiPath: faasFulfillmentApiPath
    },
    doc_Fass_Loyalty: {
        home: <FAAS_Loyalty_MainPage/>,
        apiPath: faasLoyaltyApiPath
    },
    doc_Fass_Content: {
        home: <FAAS_Content_MainPage/>,
        apiPath: faasContentApiPath
    },
    doc_Fass_CDP: {
        home: <FAAS_CDP_MainPage/>,
        apiPath: faasCdpApiPath
    },
    doc_Fass_MA: {
        home: <FAAS_MA_MainPage/>,
        apiPath: faasMaApiPath
    },
}
export const RightElement = () => {
    const [value, setValue] = useState('')
    const [info] = useAtom(HttpApiCatalog)
    const {id, version} = useParams<{ id: keyof typeof mainPage, version: TApiVersion }>()
    const apiPath = mainPage[id!].apiPath

    useScrollToTop('wy-anchor-content', info.name)
    const {data, loading} = useRequest(
        async () => {
            //@ts-ignore
            const realApiPath = version === 'v13' ? (apiPath?.[info.name]?.[version] ?? apiPath?.[info.name]?.['v10'].replace('mdpreview', version)) : (apiPath?.[info.name]?.[version] ?? apiPath?.[info.name]?.['v10']);
            const res = await fetch(realApiPath, {
                method: 'GET',
            })
            const data = await res.text()
            const processor = unified().use(remarkParse).use(remarkStringify).use(remarkHeadings)
            let headerList = (await processor.process(data)).data.headings as Heading[]
            const anchorData = transHeaderListToAnchorData(headerList)
            return {data, anchorData}
        },
        {refreshDeps: [info.name, version]},
    )
    const renderContent = () => {
        if (info.name === 'default') return mainPage[id!].home
        return (
            <div className="flex md-container min-h-[700px]">
                <ReactMarkdown
                    className="reactMarkdown"
                    components={reactMarkdownComponents}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                >
                    {data?.data as any}
                </ReactMarkdown>
                <Anchor
                    // showInkInFixed
                    // affix={false}
                    getContainer={() => document.querySelector('#wy-anchor-content') as AnchorContainer}
                >
                    {data?.anchorData?.map((parent) => (
                        <Link href={toLowerCaseSpiltBylineForAnchor(parent.value)} title={parent.value}
                                key={parent.value}>
                            {parent.children?.map((child) => (
                                <Link href={toLowerCaseSpiltBylineForAnchor(child.value)} title={child.value}
                                        key={child.value}>
                                    {child.children?.map((childItem) => (
                                        <Link
                                            href={((childItem.value ?? "").match(/{(\#[\s\S]*)}/) || ['', toLowerCaseSpiltBylineForAnchor(childItem.value)])[1]}
                                            title={(childItem.value ?? "").replace(/{\#([\s\S]*)}/g, () => "")}
                                            key={childItem.value}
                                        />
                                    ))}
                                </Link>
                            ))}
                        </Link>
                    ))}
                </Anchor>
            </div>
        )
    }
    return (
        <Spin spinning={loading}>
            <div className="h-full overflow-auto flex-1" id="wy-anchor-content" key={info.name}>
                {/* <div className="flex flex-row h-am70 w-full items-center justify-between">
                    <div className="ml-8 text-am16 ">
                        <span className="mr-1">{id === 'PlatformAPIs' ? 'HTTP API' : 'Sales Experience'}</span>
                        <DownOutlined/>
                    </div>
                    <SearchInput style={{marginRight: 360}} placeholder="Search" value={value} onChange={setValue}/>
                </div> */}
                {renderContent()}
                {!loading ? <Footer width={800}/> : null}
            </div>
        </Spin>
    )
}
